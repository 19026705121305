<template>
  <div class="w-full h-full px-3 py-5">
    <loader v-if="isLoading" size="xxs" :loader-image="false" />
    <template v-else>
      <div class="flex flex-col gap-8">
        <div class="flex justify-start items-center gap-8">
          <div class="flex justify-start items-center gap-0">
            <back-button
              @onClick="$router.back()"
              variant="secondary"
              style="padding: 0"
            />
            <h1 class="text-xl text-left font-extrabold">Time Off</h1>
          </div>
          <breadcrumb :items="breadcrumbs" />
        </div>
        <div class="flex flex-col gap-6 w-full">
          <div class="flex flex-col gap-4 w-full">
            <c-card class="flex justify-start items-center gap-6 py-6 px-4">
              <c-card class="flex justify-center items-center p-2">
                <c-icon class-name="text-jet" icon-name="icon-union" size="s" />
              </c-card>
              <div
                class="w-full grid grid-cols-4 gap-4 divide-x divide-romanSilver"
              >
                <div class="flex flex-col">
                  <span class="text-xs font-semibold uppercase text-jet"
                    >Total Leave Entitlements (Days)</span
                  >
                  <span class="text-lg font-extrabold text-desire">{{
                    summary.leaveEntitlementDays
                  }}</span>
                </div>
                <div class="flex flex-col pl-4">
                  <span class="text-xs font-semibold uppercase text-jet"
                    >Total Outstanding Leave (Days)</span
                  >
                  <span class="text-lg font-extrabold text-carrotOrange">{{
                    summary.outstandingLeaveDays
                  }}</span>
                </div>
                <div class="flex flex-col pl-4">
                  <span class="text-xs font-semibold uppercase text-jet"
                    >Total Eligible Carry Forward (Days)</span
                  >
                  <span class="text-lg font-extrabold text-mediumSeaGreen">{{
                    summary.carryForward ? summary.carryForward : 0
                  }}</span>
                </div>
                <div class="flex flex-col pl-4">
                  <span class="text-xs font-semibold uppercase text-jet"
                    >Total Outstanding Leave Allowance (₦)</span
                  >
                  <span class="text-lg font-extrabold text-optima">{{
                    convertToCurrency(summary.totalLeaveOutstandingAmount)
                  }}</span>
                </div>
              </div>
            </c-card>
            <c-card class="p-5 pt-0">
              <CardFooter
                printcard
                reloadcard
                :showfilter="false"
                search-placeholder="Search by employee name"
                @reload="getOrgYearEndBalanceDetails()"
                @sortType="
                  $queryBuilder({ sort: $event }, getOrgYearEndBalanceDetails)
                "
                @searchResult="
                  $queryBuilder({ search: $event }, getOrgYearEndBalanceDetails)
                "
              />
            </c-card>
          </div>

          <c-table
            :headers="headers"
            :items="balances"
            aria-label="year end leave balance table"
            :loading="isFetching"
            :has-number="true"
            :page-sync="true"
            :pagination-list="pagination"
            @page="$queryBuilder({ page: $event }, getOrgYearEndBalanceDetails)"
            @itemsPerPage="
              $queryBuilder({ perPage: $event }, getOrgYearEndBalanceDetails)
            "
            v-if="isFetching || balances.length"
          >
            <template v-slot:item="{ item }">
              <div
                v-if="item.employeeData"
                class="flex justify-start items-start gap-2"
              >
                <div>
                  <span
                    v-if="item.data.employeeData.photo"
                    class="flex justify-center items-center w-9 h-9"
                  >
                    <img
                      :src="item.data.employeeData.photo"
                      class="w-9 h-9 rounded"
                      alt="profile photo"
                    />
                  </span>
                  <div
                    v-else
                    class="flex justify-center items-center w-9 h-9 rounded border"
                  >
                    <span
                      class="text-blueCrayola text-center font-semibold text-lg p-2"
                    >
                      {{
                        $getInitials(
                          `${item.data.employeeData.fname} ${item.data.employeeData.lname}`
                        )
                      }}
                    </span>
                  </div>
                </div>
                <div class="flex flex-col justify-start gap-1">
                  <span class="font-semibold text-darkPurple text-base">
                    {{ item.data.employeeData.fname }}
                    {{ item.data.employeeData.lname }}
                  </span>
                  <span class="font-normal text-romanSilver text-xs uppercase">
                    {{
                      item.data.userDesignation &&
                      item.data.userDesignation.name
                    }}
                  </span>
                </div>
              </div>
              <span v-if="item.userLevel" class="text-darkPurple text-sm">
                {{ item.data.userLevel && item.data.userLevel.name }}
              </span>
              <span
                v-if="item.maximumApplicableDays"
                class="text-darkPurple text-sm"
              >
                {{ item.data.maximumApplicableDays }}
              </span>
              <span v-if="item.leaveBalance" class="text-darkPurple text-sm">
                {{ item.data.leaveBalance }}
              </span>
              <span
                v-if="item.carriedForwardLimit"
                class="text-darkPurple text-sm"
              >
                {{ item.data.carriedForwardLimit }}
              </span>
              <span v-if="item.leaveAllowance" class="text-darkPurple text-sm">
                {{
                  item.data.leaveAllowance ? item.data.leaveAllowance : "---"
                }}
              </span>
              <div v-if="item.id" class="cursor-pointer">
                <div @click="openSummary(item.data.employeeData)">
                  <c-icon icon-name="icon-eye" size="xs" />
                </div>
              </div>
            </template>
          </c-table>

          <div
            v-else
            class="flex flex-col justify-center items-center gap-0 px-10"
          >
            <Icon
              icon-name="paygrade_Illustration"
              size=""
              class-name="w-56 h-56"
            />
            <p
              class="font-normal text-base leading-5 text-jet text-center w-1/2"
            >
              Year end leave balances can be found here. This is empty now, but
              you can start adding year end leave balances to populate this
              area.
            </p>
          </div>

          <FilterTable
            v-if="isOpenFilter"
            :filter-data="filterOptions"
            @close="isOpenFilter = false"
            @submit="isOpenFilter = false"
          />

          <ViewDaysBackRequest ref="viewDaysBackRequestRef" />
          <AddLeaveRequest ref="leaveRequestRef" />
          <ViewLeaveRequest ref="viewRequestRef" />
          <GetLeaveBackRequest ref="getLeaveBackRef" />
        </div>
      </div>
    </template>

    <RightSideBar
      v-if="isOpen"
      @close="isOpen = false"
      submit="View Leave History"
      button-class="bg-dynamicBackBtn text-white"
      @submit="$router.push({name: 'LeaveHistory', params: {id:  selectedEmployee.id}})"
      width="width:520px"
      submit-button-width="11.875rem"
      :loading="loading"
    >
      <template v-slot:title>
        <div class="flex justify-start items-center gap-0">
          <back-button
            @onClick="isOpen = false"
            variant="secondary"
            style="padding: 0"
          />
          <span class="text-darkPurple font-bold text-lg">Leave Summary</span>
        </div>
      </template>
      <div class="w-full flex my-3">
        <CCard class="w-full flex flex-col gap-3 py-2 px-3">
          <div class="flex w-full">
            <p
              class="flex flex-grow uppercase text-romanSilver text-xs font-semibold"
            >
              Employee Name
            </p>
            <p class="flex text-sm font-semibold">
              {{ selectedEmployee.fname }} {{ selectedEmployee.lname }}
            </p>
          </div>
          <div class="flex w-full">
            <p
              class="flex flex-grow uppercase text-romanSilver text-xs font-semibold"
            >
              Job Title
            </p>
            <p class="flex text-sm font-semibold">
              {{
                selectedEmployee.orgFunction
                  ? selectedEmployee.orgFunction.name
                  : "---"
              }}
            </p>
          </div>
          <div class="flex w-full">
            <p
              class="flex flex-grow uppercase text-romanSilver text-xs font-semibold"
            >
              Level
            </p>
            <p class="flex text-sm font-semibold">
              {{
                selectedEmployee.userLevel
                  ? selectedEmployee.userLevel.name
                  : "---"
              }}
            </p>
          </div>
          <div class="flex w-full">
            <p
              class="flex flex-grow uppercase text-romanSilver text-xs font-semibold"
            >
              Function
            </p>
            <p class="flex text-sm font-semibold">
              {{
                selectedEmployee.userDesignation
                  ? selectedEmployee.userDesignation.name
                  : "---"
              }}
            </p>
          </div>
          <div class="w-full h-[1px] border mt-4" />

          <c-table
            :headers="summaryHeaders"
            :items="leaveCategories"
            :has-number="false"
            aria-label="year end leave balance table"
            v-if="leaveCategories.length"
            class="-mt-2"
          >
            <template v-slot:item="{ item }">
              <div
                v-if="item.name"
                class="flex justify-start items-start gap-2"
              >
              {{ item.data.name }}
            </div>
              <div
                v-if="item.daysUsed"
                class="flex justify-start items-start gap-2"
              >
              {{ item.data.daysUsed }}
            </div>
              <div
                v-if="item.balance"
                class="flex justify-start items-start gap-2"
              >
              {{ item.data.balance }}
            </div>
            </template>
          </c-table>
        </CCard>
      </div>
    </RightSideBar>
  </div>
</template>

<script>
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import CTable from "@scelloo/cloudenly-ui/src/components/table";
import CCard from "@scelloo/cloudenly-ui/src/components/card";
import CardFooter from "@/components/CardFooter";
import FilterTable from "@/components/FilterTable";
import CIcon from "@/components/Icon";
import RightSideBar from "@/components/RightSideBar";

export default {
  components: {
    Breadcrumb,
    BackButton,
    CCard,
    CTable,
    CardFooter,
    FilterTable,
    CIcon,
    RightSideBar,
    ViewDaysBackRequest: () => import("./sideActions/ViewDaysBackRequest"),
    AddLeaveRequest: () => import("./sideActions/AddLeaveRequest"),
    ViewLeaveRequest: () => import("./sideActions/ViewLeaveRequest"),
    GetLeaveBackRequest: () => import("./sideActions/GetLeaveBack"),
  },
  data() {
    return {
      isOpenFilter: false,
      isOpen: false,
      isFetching: true,
      isLoading: false,
      loading: false,
      filterOptions: [],
      setQueryTimeout: null,
      queryParams: {},
      pagination: {},
      balances: [],
      leaveYear: new Date().getFullYear(),
      selectedEmployee: null,
      summary: {
        carryForward: 0,
        totalLeaveOutstandingAmount: 0,
        leaveEntitlementDays: 0,
        outstandingLeaveDays: 0,
      },
      headers: [
        { title: "Employee Name", value: "employeeData" },
        { title: "job level", value: "userLevel" },
        { title: "Leave Entitlement (Days)", value: "maximumApplicableDays" },
        { title: "Leave Balance (Days)", value: "leaveBalance" },
        { title: "Carry Forward Limit", value: "carriedForwardLimit" },
        { title: "Leave Allowance Status", value: "leaveAllowance" },
        { title: "", value: "id", image: true },
      ],
      summaryHeaders: [
        { title: "Leave Type", value: "name" },
        { title: "Total days taken(To Date)", value: "daysUsed" },
        { title: "Leave Balance", value: "balance" },
      ],
      leaveCategories: [],
    };
  },
  computed: {
    breadcrumbs() {
      return [
        { disabled: false, text: "Leave", href: "Leave", id: "Leave" },
        {
          disabled: false,
          text: "Year End Leave Balances",
          id: "Year End Leave Balances",
        },
        {
          disabled: false,
          text: `${this.leaveYear} Year End Leave Balances`,
          id: `${this.leaveYear} Year End Leave Balances`,
        },
      ];
    },
  },
  methods: {
    openSummary(employeeData) {
      this.isOpen = true;
      this.loading = true;
      this.selectedEmployee = employeeData;
      this.$_yearEndBalanceUserSummary(
        employeeData.id,
        this.$route.params.id
      ).then((response) => {
        this.leaveCategories = response.data.leaveCategories;
        this.loading = false;
      });
    },
    async getOrgYearEndBalanceDetails(params) {
      this.isFetching = true;
      this.leaveYear = this.$route.params.id;
      const payload = { year: this.leaveYear, params };

      await this.$_orgYearEndLeaveBalanceDetails(payload).then(({ data }) => {
        this.summary = data.summary;
        this.pagination = data.meta;
        this.balances = data.balances?.map((item) => ({
          ...item,
          ...item.employeeData,
        }));
        this.isFetching = false;
      });
    },

    async getManagerEmployeeYearEndBalanceDetails(params) {
      this.isFetching = true;
      this.leaveYear = this.$route.params.id;
      const payload = { year: this.leaveYear, params };

      await this.$_ManagerYearEndLeaveBalanceDetails(payload).then(
        ({ data }) => {
          this.summary = data.summary;
          this.pagination = data.meta;
          this.balances = data.balances?.map((item) => ({
            ...item,
            ...item.employeeData,
          }));
          this.isFetching = false;
        }
      );
    },

    async bootstrapModule() {
      this.isLoading = true;
      if (this.$route.query.slug === "admin") {
        await this.getOrgYearEndBalanceDetails();
      } else {
        await this.getManagerEmployeeYearEndBalanceDetails("");
      }
      this.isLoading = false;
    },
  },
  created() {
    this.bootstrapModule();
  },
};
</script>

<style>
.userContainer {
  padding: 8px;
  border-bottom: 1px dashed #878e99;
}

input[type="radio"] {
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  outline: none;
  box-shadow: 0px 0px 0px 2px #eeeeee inset;
}

input[type="radio"]:checked {
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  outline: none;
  background-color: #f15a29 !important;
  box-shadow: 0px 0px 0px 2px #eeeeee inset;
}
</style>
